import { render, staticRenderFns } from "./chengjiao.vue?vue&type=template&id=5d483274&scoped=true&"
import script from "./chengjiao.vue?vue&type=script&lang=js&"
export * from "./chengjiao.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d483274",
  null
  
)

export default component.exports